<template>
  <v-container
    id="workWithUs"
    tag="section"
    fluid
    fill-height
    :class="ShowMainPage === 'Y' ? 'pa-0' : 'pa-0'"
  >
    <v-container>
      <v-row>
          <v-col align="start" cols="12" class="pa-0 ma-1">
            <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.workWithUs') }}</strong></span>
            <v-divider></v-divider>
          </v-col>
          <v-col align="start" cols="12" class="pa-0 ma-1" v-if="ShowImg!=='N'">
            <v-card elevation-10 class="d-flex align-center justify-center">
              <v-img v-if="ShowImg!=='N'"
                :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/joinus_1000x420.jpg`)"
                cover
                class="text-white align-end justify-end"
                height="100%"
              >
                <!-- <v-card-text class="white--text">
                  <v-row justify="center" align="center">
                    <v-col cols="6" sm="6" justify="center"></v-col>
                    <v-col cols="6" sm="6" justify="center">
                      <v-card class="cardColor white--text">
                        {{ WorkWithUsBannerText }}
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text> -->
              </v-img>
            </v-card>
            <!-- <v-img
              :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/joinus_1000x420.jpg`)"
              height="100%">
            </v-img> -->
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <!-- <v-card flat color="#F8F9F9" class="mx-0 pa-0">  -->
              <base-subheading class="mb-3" dark>
                <span class="display-2"><strong>{{ $t('MeeTeeMeeNgern1.JobPosition') }}</strong></span>
                <v-divider></v-divider>
              </base-subheading>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item, index) in vDataTable.data"
                  :key="item.skey"
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    <!-- <span class="display-2">{{ $t('MeeTeeMeeNgern1.Question2') }}</span> -->
                    <span class="display-2">{{item.name}}</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="mx-auto pt-0 mt-0" flat>
                      <v-divider></v-divider>
                      <v-card-text v-html="item.description_1"></v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            <!-- </v-card> -->
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <base-subheading class="mb-3" dark>
              <span class="display-2"><strong>{{ $t('MeeTeeMeeNgern1.FormJobApplication') }}</strong></span>
              <v-divider></v-divider>
            </base-subheading>
            <v-row row wrap>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  color="black"
                  class="bg-editor custom-label-color"
                  dense hide-details 
                  v-model="firstName"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.firstName') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>              
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  color="black"
                  class="bg-editor custom-label-color"
                  dense hide-details 
                  v-model="lastName"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.lastName') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>            
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-menu
                  v-model="menuBirthday"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatThaiDate"
                      :label="$t('MeeTeeMeeNgern1.birthDay')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense flat hide-details
                    >
                    <template v-slot:label>
                      <span class="input__label text-title">{{ $t('MeeTeeMeeNgern1.birthDay') }}</span>
                    </template>
                    </v-text-field>
                  </template>
                  <v-date-picker  class="ma-0 pa-0"
                    v-model="Birthday"
                    @input="menuBirthday = false"
                    :first-day-of-week="1"
                    locale="th-TH"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-textarea
                  class="bg-editor"
                  dense
                  v-model="addr"
                  hide-details
                  outlined
                  rows="4"
                >
                <template v-slot:label>
                  <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.ADDRESS') }}</span>
                  <span class="red--text text-body">*</span>
                </template>
                </v-textarea>
              </v-col> -->
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details
                  v-model="tel"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.Tel') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details 
                  v-model="email"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.email') }}</span>
                    <span class="red--text text-body">*</span>
                  </template>
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-textarea
                  class="bg-editor"
                  dense
                  v-model="eduHist"
                  hide-details
                  outlined
                  rows="3"
                >
                <template v-slot:label>
                  <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.eduHist') }}</span>
                  <span class="red--text text-body">*</span>
                </template>
                </v-textarea>
              </v-col> -->
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-textarea
                  class="bg-editor"
                  dense
                  v-model="workHist"
                  hide-details
                  outlined
                  rows="3"
                >
                <template v-slot:label>
                  <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.workHist') }}</span>
                  <span class="red--text text-body">*</span>
                </template>
                </v-textarea>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-autocomplete
                  class="bg-editor custom-label-color"
                  dense
                  hide-details
                  outlined
                  v-model="workWithUsSkey"
                  :items="vDataTable.data"
                  item-text="name"
                  item-value="skey"
                >
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.JobApplication') }}</span>
                  </template>              
                </v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-text-field
                  class="bg-editor"
                  dense hide-details 
                  v-model="DesiredSalary"
                  outlined>
                  <template v-slot:label>
                    <span class="input__label text-body-1">{{ $t('MeeTeeMeeNgern1.DesiredSalary') }}</span>
                  </template>
                </v-text-field>
              </v-col> -->
              <!-- <v-col cols="4" sm="12" md="12" align="center" justify="center">
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center" cols="12" sm="12" md="1">
                      <v-file-input
                        label="แนบรูปถ่าย"
                        align="center" justify="center"
                        v-model="picture_base64"
                        dense flat outlined hide-details
                        clearable
                        hide-input
                        accept=".jpg,.jpeg,.bmp"   
                        @change="filename = picture_base64.name"
                        >
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="12" md="11">
                      <v-text-field
                        align="center" justify="center"
                        outlined
                        flat dense
                        hide-details
                        v-model="filename"
                        :clearable="clearFileName"
                      >
                      <template v-slot:label>
                          <span class="input__label text-title">{{ $t('MeeTeeMeeNgern1.fileImageName') }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                </v-row>
              </v-col> -->
              <v-col cols="4" sm="12" md="12" align="center" justify="center">
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center" cols="12" sm="12" md="1">
                      <v-file-input
                        label="แนบรูปถ่าย"
                        align="center" justify="center"
                        v-model="picture_base64_hist"
                        dense flat outlined hide-details
                        clearable
                        hide-input
                        accept=".jpg,.jpeg,.bmp,.pdf"
                        @change="fileHist = picture_base64_hist.name"
                        >
                        <!-- @change="item.filename = item.picture_base64.name" -->
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="12" md="11">
                      <v-text-field
                        align="center" justify="center"
                        outlined
                        flat dense
                        hide-details
                        v-model="fileHist"
                        :clearable="clearFileNameHist"
                      >
                      <template v-slot:label>
                          <span class="input__label text-title">{{ $t('MeeTeeMeeNgern1.fileHist') }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-card     
                  class="my-2 pa-0"
                  outlined
                  :style="'border: 1px solid pink;'"
                  dense
                  flat
                >
                  <v-card-text dense flat class="text--primary">
                  <v-btn dark
                    dense 
                    flat
                    text
                    class="text-body-1 ma-0 pa-0"
                    color="pink"
                    @click="CookieLaw"
                  >{{ $t('Register.messagePolicy') }}
                  </v-btn>
                  <v-divider class="ma-0 pa-0" inset vertical></v-divider>
                  <v-checkbox class='text--primary'
                    value="Y"
                    v-model="consentFlag"
                    dense flat
                  >
                    <template v-slot:label>
                      <div><span class='text--primary display-1'>{{ $t('Register.Policy') }}</span></div>
                    </template>
                  </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col align="center" cols="12" class="pt-0 pb-2 mb-0" lg="12">
                <v-btn dark
                  depressed
                  class="ma-0"
                  color="pink"
                  @click="addJobApplication"
                >{{ $t('MeeTeeMeeNgern1.send') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
    <!-- <v-row align="center"
        justify="center">
      <v-col cols="12" align="center" class="ml-7 pa-0"> -->
        <!-- <v-card flat>
          <v-row align="start">
            <v-col align="start" cols="12" class="pa-0 ma-1">
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.workWithUs') }}</strong></span>
              <v-divider></v-divider>
            </v-col>
            <v-col align="start" cols="12" class="pa-0 ma-1">
              <v-img
                :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/joinus_1000x420.jpg`)"
                height="100%">
              </v-img>
            </v-col>
            <v-col align="start" cols="12" md="6" lg="6" class="pa-1 ma-1">
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item, index) in vDataTable.data"
                  :key="item.skey"
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    <span class="display-2">{{item.name}}</span>
                    <template v-slot:actions>
                      <v-icon color="error">
                        $expand
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="mx-auto pt-0 mt-0" flat>
                      <v-divider></v-divider>
                      <v-card-text v-html="item.description_1"></v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col align="start" cols="12" md="5" lg="5" class="pa-1 ma-1">
              <v-card
                class="mx-auto pa-0 ma-0"
              >
                <v-card-title>{{ $t('MeeTeeMeeNgern1.InterestedPleaseContact') }}</v-card-title>
                <v-divider></v-divider>
                <v-list 
                  :class="ShowMainPage === 'Y' ? 'transparent pl-0 custom-bg' : 'transparent pl-0'">
                  <v-list-item>
                    <v-list-item-avatar
                      color="pink"
                    >
                      <v-icon
                        class="elevation-4"
                        dark
                      >
                        mdi-email-outline
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="pl-0">
                      <v-list-item-title><span class="display-1">{{ $t('MeeTeeMeeNgern1.EMAIL') }}:</span></v-list-item-title>
                      <v-list-item-subtitle class="body-1 black--text text-wrap">HR@meetee.co.th</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-avatar color="pink">
                      <v-icon
                        class="elevation-4"
                        dark
                      >
                        mdi-phone
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><span class="display-1">{{ $t('MeeTeeMeeNgern1.PHONE') }}:</span></v-list-item-title>
                      <v-list-item-subtitle class="body-1 black--text text-wrap">02-025-6999</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card> -->
      <!-- </v-col>
    </v-row> -->
      <v-dialog
        v-model="dialog.dialogPolicy"
        width="800px"
      >
        <v-card>
          <!-- <v-card-title>
            <span class="text-h5">Use Google's location service?</span>
          </v-card-title> -->
          <v-card-text>
            <cookiepolicy></cookiepolicy>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink"
              dark
              text
              @click="dialog.dialogPolicy = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
  <!-- <v-container fill-height fluid
    id="workWithUs"
    tag="section"
  >
    <v-row align="center"
        justify="center">
        <v-col cols="12" align="center" class="ma-0 pa-0">
            <base-subheading><span class="headline">{{ $t('MeeTeeMeeNgern1.workWithUs') }}</span></base-subheading>
            <v-card flat max-width="820">
                <v-row align="start">
                    <v-col align="start" cols="12" class="pa-0 ma-1">
                       {{ $t('MeeTeeMeeNgern1.aboutUs2') }}
                    </v-col>
                    <v-col align="start" class="ma-0 pa-0">
                        {{ $t('MeeTeeMeeNgern1.aboutUs2') }}
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'HomeAbout',
    props: {
      ShowImg: {
        type: String,
        required: true,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
    components: {
      workWithUsFeedCard: () => import('@/components/workWithUsFeedCard'),
      cookiepolicy: () => import('@/components/cookie-policy'),
    },
    async created () {
      this.getGlobalWorkWithUsBannerText()/* work_with_us_banner_text */
      await this.retrieveFrontWorkWithUs(localStorage.getItem('language'))
      this.utils.UtilsAddVisitCountMenu(this.currentRouteName, 0) /* 1 visit count menu */
    },
    data: () => ({
      utils: require('@/utils'), /* 2 visit count menu */
      layout: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      workwithus: require('@/data/workwithus.json'),
      product: require('@/data/product.json'),
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      WorkWithUsBannerText: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      regMobilePhone: /^((0)(\d{9}))$/,
      workWithUsSkey: '',
      firstName: '',
      lastName: '',
      otpCode: 'VUEJ',
      idCard: '',
      telephone: '',
      provinceId: '',
      provinceName: '',
      districtId: '',
      districtName: '',
      subDistrictId: '',
      subDistrictName: '',
      zipCode: '',
      refCode: '',
      email: '',
      lineId: '',
      menuBirthday: '',
      Birthday: new Date(Date.now()).toISOString().slice(0, 10),
      consentFlag: '',
      tel: '',
      addr: '',
      eduHist: '',
      workHist: '',
      image_path: '',
      filename: '',
      remark: '',
      picture_base64: '',
      fileHist: '',
      picture_base64_hist: '',
      dialog: {
        dialogPolicy: false,
      },
    }),
    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
    computed: {
      currentRouteName () { /* 3 visit count menu */
        return this.$route.name
      },
      formatThaiDate () {
        var d = new Date(this.Birthday)
        return moment(d).add(543, 'year').format('DD/MM/YYYY')
      }
    },
    methods: {
      async selectLanguage (language) {
        await this.retrieveFrontWorkWithUs(language)
      },
      async addVisitCountMenu (currentRouteName, refSkey) {
        await this.axios.post(process.env.VUE_APP_API + '/visit_count/addVisitCountMenu' + process.env.VUE_APP_DATABASE_TYPE, {
          win_name: currentRouteName,
          ref_skey: refSkey
        })
          .then(response => {
            if (response.data.isSuccess) {
              console.log(response.data, 'response.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      async retrieveFrontWorkWithUs (languageID) {
        console.log(this.currentRouteName, 'this.currentRouteName')
        var aLimitShow = this.limitShow
        if (!aLimitShow) { aLimitShow = 0 }
        await this.axios.post(process.env.VUE_APP_API + '/work_with_us/retrieveFrontWorkWithUs' + process.env.VUE_APP_DATABASE_TYPE, {
          limit: aLimitShow,
          language_id: languageID,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.vDataTable.data = response.data.data
              console.log(this.vDataTable.data, 'this.vDataTable.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getGlobalWorkWithUsBannerText () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalWorkWithUsBannerText', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.WorkWithUsBannerText = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      validateMobilePhoneNumber (myTelephone) {
        var ret = true
        if (!myTelephone.match(this.regMobilePhone)) {
          ret = false
        }
        return ret
      },
      validateReg () {
        var messageCustom = ''
        /* messageMandatory: 'Please Enter Field *',
  messageFirstName: 'Please Enter First Name.',
  messageLastName: 'Please Enter Last Name.',
  messageAddr: 'Please Enter Address.',
  messageTel: 'Please Enter Telephone.',
  messageEmail: 'Please Enter e-mail.',
  messageEduHist: 'Please Enter Education.',
  messageWorkHist: 'Please Enter Work History.', */
        if (!this.firstName) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageMandatory') + '<br />'
        }
        if (!this.lastName) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageLastName') + '<br />'
        }
        /* if (!this.addr) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageAddr') + '<br />'
        } */
        if (!this.tel) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageTel') + '<br />'
        } else {
          if (!this.validateMobilePhoneNumber(this.tel)) {
            messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageTelIncorrect') + '<br />'
          }
        }
        if (!this.email) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageEmail') + '<br />'
        } else {
          if (!this.reg.test(this.email)) {
            messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageEmailIncorrect') + '<br />'
          }
        }
        /* if (!this.eduHist) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageEduHist') + '<br />'
        } */
        if (!this.workHist) {
          messageCustom = messageCustom + this.$t('MeeTeeMeeNgern1.messageWorkHist') + '<br />'
        }
        if (!this.consentFlag) {
          this.$swal({type: 'warning', title: this.$t('SubscribeMaint.messagePolicy')})
          return
        }
        /* var bd = new Date(this.Birthday)
        var currentDt = new Date()
        var dateBd = moment(bd).format('YYYY-MM-DD')
        var date20 = moment(currentDt).add(-20, 'Y').format('YYYY-MM-DD')
        if (moment(date20).isBefore(dateBd)) {
          messageCustom = messageCustom + 'ผู้สมัครต้องอายุไม่ต่ำกว่า 20 ปี' + '<br />'
        } */
        if (messageCustom) {
          this.$swal({type: 'warning', title: messageCustom, showConfirmButton: true})
          return false
        } else {
          return true
        }
      },
      async addJobApplication () {
        if (this.validateReg() === true) {
          if (!this.firstName) {
            this.firstName = ''
          }
          if (!this.lastName) {
            this.lastName = ''
          }
          if (!this.email) {
            this.email = ''
          }
          if (!this.tel) {
            this.tel = ''
          }
          if (!this.addr) {
            this.addr = ''
          }
          if (!this.eduHist) {
            this.eduHist = ''
          }
          if (!this.workHist) {
            this.workHist = ''
          }
          if (!this.workWithUsSkey) {
            this.workWithUsSkey = ''
          }
          if (!this.DesiredSalary) {
            this.DesiredSalary = ''
          }
          await this.axios.post(process.env.VUE_APP_API + '/work_with_us/addJobApplication' + process.env.VUE_APP_DATABASE_TYPE, {
            first_name: this.firstName,
            last_name: this.lastName,
            birthday: this.Birthday,
            addr: this.addr,
            policy_flag: this.consentFlag,
            email: this.email,
            tel: this.tel,
            edu_hist: this.eduHist,
            work_hist: this.workHist,
            work_with_us_skey: this.workWithUsSkey,
            desired_salary: this.DesiredSalary,
            picture_base64: this.picture_base64,
            picture_base64_hist: this.picture_base64_hist,
          })
            .then(response => {
              if (response.data.isSuccess) {
                this.uploadFile(response.data.jsSkey)
                this.uploadFileHist(response.data.jsSkey)
                this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
                this.resetData()
              } else {
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.$swal({type: 'error', title: e.message})
            })
        }
      },
      async uploadFile (skey) {
        var isSuccess = true
        var msg = ''
        var isSave = false
        var i = 0
        var perCentComplete = 0
        let formData = new FormData()
        formData.append('skey', skey)
        formData.append('file', this.picture_base64)
        await this.axios.post(process.env.VUE_APP_API + '/work_with_us/uploadPicture' + process.env.VUE_APP_DATABASE_TYPE, formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
          }
        ).then(async response => {
          if (!response.data.isSuccess) {
            msg = msg + response.data.reasonText + '<br>'
          }
        })
        .catch(async e => {
          msg = msg + response.data.reasonText + '<br>'
        })
      },
      async uploadFileHist (skey) {
        var isSuccess = true
        var msg = ''
        var isSave = false
        var i = 0
        var perCentComplete = 0
        let formData = new FormData()
        formData.append('skey', skey)
        formData.append('file', this.picture_base64_hist)
        await this.axios.post(process.env.VUE_APP_API + '/work_with_us/uploadPictureHist' + process.env.VUE_APP_DATABASE_TYPE, formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
          }
        ).then(async response => {
          if (!response.data.isSuccess) {
            msg = msg + response.data.reasonText + '<br>'
          }
        })
        .catch(async e => {
          msg = msg + response.data.reasonText + '<br>'
        })
      },
      clearFileNameHist () {
        this.picture_base64_hist = null
        this.fileHist = ''
      },
      clearFileName () {
        this.picture_base64 = null
        this.filename = ''
      },
      resetData () {
        // this.productId = null
        // this.productName = null
        this.firstName = null
        this.lastName = null
        this.tel = null
        this.provinceId = null
        this.provinceName = null
        this.districtId = null
        this.districtName = null
        this.subDistrictId = null
        this.subDistrictName = null
        this.zipCode = null
        this.refCode = null
        this.email = null
        this.lineId = null
        this.idCard = null
        this.Birthday = new Date(Date.now()).toISOString().slice(0, 10)
        this.addr = null
        this.eduHist = ''
        this.workHist = ''
        this.image_path = ''
        this.filename = ''
        this.remark = ''
        this.picture_base64 = ''
        this.fileHist = ''
        this.picture_base64_hist = ''
        this.workWithUsSkey = ''
        this.DesiredSalary = ''
        this.consentFlag = ''
      },
      CookieLaw () {
        this.dialog.dialogPolicy = true
      },
    }
  }
</script>
<style scoped>
.cardColor {
   background-color: rgba(255, 255, 255, 0.5) !important;
   border-color: white !important;
 }
 .bg-editor {
    /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(255, 255, 255) !important;
  }
</style>
